import React from "react";
import { Link } from "gatsby";
import { categoryParams } from "../../../node-category";

const Nav = ({ showMenu, closeMenu }) => {

  const links = [
    {
      url: `#`,
      label: "Tote Bags",
      items: [
        { url: `${categoryParams.urlPrefix}tote-bags-personnalises/`, label: "Tote bags personnalisés" },
        { url: `${categoryParams.urlPrefix}tote-bags-personnalises-coton-biologique/`, label: "Tote bags personnalisés biologiques" },
        { url: `${categoryParams.urlPrefix}tote-bags-grand-format-xxl-personnalises/`, label: "Tote bags personnalisés grand format" },
        // { url: `${categoryParams.urlPrefix}sacs-coton-ecologique-personnalises/`, label: "Tote bags personnalisés écologiques" }
      ]
    },
    {
      url: `#`,
      label: "Cabas",
      items: [
        { url: `${categoryParams.urlPrefix}cabas-personnalises/`, label: "Sacs cabas personnalisés" },
        { url: `${categoryParams.urlPrefix}sacs-en-toile-de-jute-personnalises/`, label: "Sacs en toile de jute personnalisés" },
        { url: `${categoryParams.urlPrefix}sac-a-dos-personnalise-publicitaire/`, label: "Sacs à dos publicitaire personnalisés" },
        // { url: `${categoryParams.urlPrefix}sac-de-sport-personnalise-publicitaire/`, label: "Sacs de sport publicitaire personnalisés" },
        // { url: `${categoryParams.urlPrefix}sac-a-pain-tissu-personnalise-publicitaire/`, label: "Sacs à pain publicitaire personnalisés" },
        // { url: `${categoryParams.urlPrefix}sac-tissu-personnalise-publicitaire/`, label: "Tous nos sacs en tissu personnalisés" },
      ]
    },
    {
      url: `#`,
      label: "Pochettes",
      items: [
        { url: `${categoryParams.urlPrefix}pochettes-personnalisees/`, label: "Pochettes personnalisées" },
        { url: `${categoryParams.urlPrefix}trousses-personnalisees/`, label: "Trousses personnalisées" }
      ]
    },
    // {
    //   url: `${categoryParams.urlPrefix}tote-bags-francais-personnalises/`,
    //   label: "Tote bags Français",
    // },
    {
      url: '#',
      label: "Tote bags Français",
      items: [
        { url: `${categoryParams.urlPrefix}tote-bags-francais-personnalises`, label: `Tote bags personnalisés` },
        { url: `${categoryParams.urlPrefix}sacs-cabas-publicitaires-personnalisees-made-in-france`, label: `Sacs cabas personnalisés` },
        { url: `${categoryParams.urlPrefix}pochons-publicitaires-personnalisees-made-in-france`, label: `Pochons personnalisés` },
        { url: `${categoryParams.urlPrefix}trousses-publicitaires-personnalisees-made-in-france`, label: `Trousses personnalisées` },
      ]
    },
    {
      url: `/tote-bag-sur-mesure/`,
      label: "Fabrication spéciale",
    }
  ];

  return <nav id="main-nav" className={`flex px-0 ${showMenu ? 'showMenu' : 'hidden'} `}>
    <ul className="flex space-x-2 justify-between">
      <li className="block lg:hidden self-start">
        <button type="button" className="bg-transparent border-none p-0 text-white font-bold" onClick={closeMenu}>X</button>
      </li>
      {links.map((link, i) => (
        <li key={i}>
          {link.items && (
            <div onClick={closeMenu} to={link.url} className="group relative flex flex-col">
              <span>{link.label}</span>
              <ul
                className="md:hidden md:group-hover:block subMenu top-5"
              >
                {link.items.map((item, j) => (
                  <li key={j} className="group/item">
                    <Link className="md:group-hover/item:text-white" onClick={closeMenu} to={item.url}>{item.label}</Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!link.items && (
            <Link to={link.url} className="group relative flex flex-col">{link.label}</Link>
          )}
          {/* TOTEBAG FR */}
          {i === 3 && (<div className="flex">
            <div className="flex-1 h-1 bg-blue"></div>
            <div className="flex-1 h-1 bg-white"></div>
            <div className="flex-1 h-1 bg-red-danger"></div>
          </div>)}
        </li>
      ))}

    </ul>
  </nav>
};

export default Nav;
