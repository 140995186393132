import React from "react";
import { Link } from "gatsby";

const TopNav = () => {
  const links = [
    // {
    //   url: "#",
    //   label: "À propos",
    //   items: [
    //     { label: "Qui sommes nous", url: "/l-atelier-du-tote-bag" },
    //     { label: "Nos engagements", url: "/l-atelier-du-tote-bag" }
    //   ]
    // },
    { url: "/l-atelier-du-tote-bag", label: "Qui sommes-nous" },
    { url: `/contacter-l-atelier-du-tote-bag`, label: "Contact" },
    { url: "/tote-bags-inspirations", label: "Nos réalisations" },
    // { url: "/le-blog-la-fabrique-du-coton-imprimeur-de-textile-personnalise", label: "Le blog" }
  ];

  return (
    <div id="top-bar" className="bg-blue-hover ">
      <div className="container flex  lg:flex-row flex-col-reverse justify-center py-2 lg:justify-between flex-wrap items-center">
        <div className="text-white text-xs uppercase font-semibold text-center md:text-left">Pour toute demande de devis urgente : <a className="text-white" href="mailto:devis@latelierdutotebag.com">devis@latelierdutotebag.com</a></div>
        <div className="flex justify-end ">
          <div className="">
            <ul
              className="top-bar-nav flex items-center mb-0 uppercase text-xs"
            >
              {links.map((link, i) => (
                <li key={i}>
                  {link.items && (
                    <div to={link.url} className="group relative flex flex-col text-white">
                      <div>{link.label}</div>
                      <ul className="hidden group-hover:block subMenu top-4">
                        {link.items.map((item, j) => (
                          <li key={j} className="group/item">
                            <Link className="group-hover/item:text-white" to={item.url}>{item.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {!link.items && (
                    <Link to={link.url} className="group relative flex flex-col">{link.label}</Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
};

export default TopNav;
